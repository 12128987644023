import { useEffect, useState } from "react";
import { useGameContext } from "contexts/gameContext";
import { loadGameFromId, loadQuizFromId } from "utils/supabase";
import { createGame } from "utils/gameService";
import SendInvite from "components/game_flow/sendInvite";
import AcceptInvite from "components/game_flow/acceptInvite";
import PlayerStates from "components/game_flow/playerStates";
import MultiplayerEnd from "components/game_flow/multiplayerEnd";
import GameIntro from "components/partials/gameIntro";
import GameRules from "components/partials/gameRules";
import StartCountdown from "components/partials/startCountdown";
import { useUserContext } from "contexts/authContext";
import { Link } from "react-router-dom";
import MultiplayerFlowV3 from "components/game_flow/mFlowv3";
import Loader from "components/partials/loader";
import { useWebSocket } from "contexts/webSocketContext";
import { markReady } from "utils/supabase";

interface PlayFriendProps {
	paramGameId?: string;
}

function PlayFriend({ paramGameId }: PlayFriendProps) {
	// starting states
	const { setActiveQuiz, activeQuiz, setActiveGame, activeGame } =
		useGameContext();
	// get user
	const { user: fullUser } = useUserContext();
	const user = fullUser!.gameUser;
	// add a ref that will store the game, initialize from context
	const [lastGame, setLastGame] = useState<Game | null>(null);
	const { ws, initializeWebSocket } = useWebSocket()!;

	// game state
	const [gameState, setGameState] = useState("start");
	const [playerOneReady, setPlayerOneReady] = useState(true);
	const [playerTwoReady, setPlayerTwoReady] = useState(false);
	const [startCountdown, setStartCountdown] = useState<number | null>(null);
	const [startTime, setStartTime] = useState<number | null>(
		activeGame?.startTime || null
	);

	const startGame = () => setGameState("inProgress");
	const endGame = (lastGame: Game) => {
		setLastGame(lastGame);
		setGameState("endGame");
	};

	// leader creates game on firebase
	useEffect(() => {
		if (!activeQuiz || activeGame || paramGameId) return;
		console.log("leader creates game");
		let quizData = activeQuiz;
		const gameMode = "friend" as GameMode;

		const initializeGame = async () => {
			console.log("creating game");
			const newGame = await createGame(
				user,
				quizData as QuizDetails,
				gameMode
			);
			setActiveGame(newGame);
			initializeWebSocket(newGame.id!, user.id!);
		};

		initializeGame();
	}, []);

	// follower loads game from paramGameId
	useEffect(() => {
		if (!paramGameId || activeGame) return;
		loadGameFromId(paramGameId)
			.then((game) => {
				setActiveGame(game);
				initializeWebSocket(game!.id!, user.id!);
				return game;
			})
			.then((game) => {
				return loadQuizFromId(game!.quizId!);
			})
			.then((quizData) => {
				// Set active quiz in the next then method
				setActiveQuiz(quizData);
			});
	}, []);

	// send start time to leader
	useEffect(() => {
		if (!paramGameId || !ws || !startTime) return;
		ws!.send(JSON.stringify({ startTime }));
	}, [startTime]);

	// receive start time from follower
	useEffect(() => {
		if (paramGameId || !ws || !activeGame) return;

		ws.onmessage = function (event) {
			try {
				const data = JSON.parse(event.data);
				console.log(data.startTime);
				if (data.startTime) {
					loadGameFromId(activeGame!.id!).then((game) => {
						setActiveGame(game);
						setStartTime(game.startTime!);
						setPlayerOneReady(game!.playerData[0]?.ready || false);
						setPlayerTwoReady(game!.playerData[1]?.ready || false);
					});
				}
			} catch (error) {
				console.error(error);
			}
		};

		// Cleanup function
		return () => {
			ws.onmessage = null;
		};
	}, [ws]);

	// Countdown to start when both players are ready
	useEffect(() => {
		if (
			playerOneReady &&
			playerTwoReady &&
			gameState === "start" &&
			startTime !== null &&
			startCountdown === null
		) {
			const timeDifference = Math.floor((startTime - Date.now()) / 1000);
			setStartCountdown(timeDifference);
			const countdownInterval = setInterval(() => {
				setStartCountdown((prev) => {
					if (prev === 1) {
						clearInterval(countdownInterval);
						startGame();
						return 0;
					}
					return prev! - 1;
				});
			}, 1000);
		}
	}, [startTime, playerTwoReady, playerOneReady, gameState, startCountdown]);

	const markUserReady = async () => {
		const updatedGame = await markReady(activeGame!.id!, user);
		setPlayerOneReady(updatedGame.playerData[0]?.ready || false);
		setPlayerTwoReady(updatedGame.playerData[1]?.ready || false);
		setActiveGame(updatedGame);
		setStartTime(updatedGame!.startTime!);
	};

	if ((!activeQuiz || !activeGame || !user) && !lastGame) {
		return (
			<Loader
				message={
					"Our AI is doing its thing (may take up to 30 seconds)..."
				}
			/>
		);
	}

	return (
		<>
			{gameState === "start" && (
				<>
					<header className="flex-col gap-4">
						<Link to="/">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g id="backArrow">
									<path
										id="Vector"
										d="M15.5307 18.9694C15.6004 19.0391 15.6557 19.1218 15.6934 19.2128C15.7311 19.3039 15.7505 19.4015 15.7505 19.5C15.7505 19.5985 15.7311 19.6961 15.6934 19.7872C15.6557 19.8782 15.6004 19.9609 15.5307 20.0306C15.461 20.1003 15.3783 20.1556 15.2873 20.1933C15.1962 20.231 15.0986 20.2504 15.0001 20.2504C14.9016 20.2504 14.804 20.231 14.7129 20.1933C14.6219 20.1556 14.5392 20.1003 14.4695 20.0306L6.96948 12.5306C6.89974 12.461 6.84443 12.3783 6.80668 12.2872C6.76894 12.1962 6.74951 12.0986 6.74951 12C6.74951 11.9014 6.76894 11.8038 6.80668 11.7128C6.84443 11.6217 6.89974 11.539 6.96948 11.4694L14.4695 3.96938C14.6102 3.82864 14.8011 3.74958 15.0001 3.74958C15.1991 3.74958 15.39 3.82864 15.5307 3.96938C15.6715 4.11011 15.7505 4.30098 15.7505 4.5C15.7505 4.69902 15.6715 4.88989 15.5307 5.03063L8.56041 12L15.5307 18.9694Z"
										fill="#71717A"
									/>
								</g>
							</svg>
						</Link>
						<GameIntro quizData={activeQuiz! as QuizDetails} />
					</header>
					<main className="p-4 flex flex-col gap-8">
						<GameRules gameMode={activeGame!.gameMode} />
						{playerOneReady &&
							!playerTwoReady &&
							activeGame!.players[0].id === user.id && (
								<SendInvite gameId={activeGame!.id!} />
							)}
						<PlayerStates game={activeGame!} />
						{startCountdown !== null && (
							<StartCountdown countdown={startCountdown} />
						)}
					</main>
					<footer>
						{playerOneReady &&
							!playerTwoReady &&
							activeGame!.players[0].id !== user.id && (
								<AcceptInvite markReady={markUserReady} />
							)}
					</footer>
				</>
			)}
			{gameState === "inProgress" && (
				<MultiplayerFlowV3 endGame={endGame} />
			)}
			{gameState === "endGame" && <MultiplayerEnd game={lastGame!} />}
		</>
	);
}

export default PlayFriend;
