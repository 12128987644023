import { createContext, useContext, useState, ReactNode } from "react";

interface ContextProps {
	activeQuiz: QuizDetails | null;
	setActiveQuiz: React.Dispatch<React.SetStateAction<QuizDetails | null>>;
	activeGame: Game | null;
	setActiveGame: React.Dispatch<React.SetStateAction<Game | null>>;
	markQuizAsSeen: (quizId: string) => void;
	markGameAsPlayed: (gameId: string) => void;
	getSeenQuizzes: () => string[];
	getPlayedGames: () => string[];
}

const GameContext = createContext<ContextProps | undefined>(undefined);

function GameProvider({ children }: { children: ReactNode }) {
	const [activeQuiz, setActiveQuiz] = useState<QuizDetails | null>(null);
	const [activeGame, setActiveGame] = useState<Game | null>(null);

	// Local Storage for seen quizzes
	const markQuizAsSeen = (quizId: string) => {
		const seenQuizzes = JSON.parse(
			localStorage.getItem("seenQuizzes") || "[]"
		);
		if (!seenQuizzes.includes(quizId)) {
			seenQuizzes.push(quizId);
			localStorage.setItem("seenQuizzes", JSON.stringify(seenQuizzes));
		}
	};

	const getSeenQuizzes = (): string[] => {
		return JSON.parse(localStorage.getItem("seenQuizzes") || "[]");
	};

	// Session Storage for played games
	const markGameAsPlayed = (gameId: string) => {
		const playedGames = JSON.parse(
			sessionStorage.getItem("playedGames") || "[]"
		);
		if (!playedGames.includes(gameId)) {
			playedGames.push(gameId);
			sessionStorage.setItem("playedGames", JSON.stringify(playedGames));
		}
	};

	const getPlayedGames = (): string[] => {
		return JSON.parse(sessionStorage.getItem("playedGames") || "[]");
	};

	return (
		<GameContext.Provider
			value={{
				activeQuiz,
				setActiveQuiz,
				activeGame,
				setActiveGame,
				markQuizAsSeen,
				markGameAsPlayed,
				getSeenQuizzes,
				getPlayedGames,
			}}
		>
			{children}
		</GameContext.Provider>
	);
}

function useGameContext() {
	const context = useContext(GameContext);
	if (context === undefined) {
		throw new Error("useGameContext must be used within a GameProvider");
	}
	return context;
}

export { GameProvider, useGameContext };
