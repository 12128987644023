import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	useEffect,
} from "react";

interface WebSocketContextType {
	ws: WebSocket | null;
	initializeWebSocket: (gameId: string, userId: string) => void;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [ws, setWs] = useState<WebSocket | null>(null);

	const initializeWebSocket = useCallback(
		(gameId: string, userId: string) => {
			const wsUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/${gameId}/${userId}`;
			const newWs = new WebSocket(wsUrl);

			newWs.onopen = () => console.log("WebSocket opened");
			newWs.onclose = () => console.log("WebSocket closed");

			setWs(newWs);

			const cleanup = () => {
				console.log("Cleaning up WebSocket");
				if (
					newWs.readyState === WebSocket.OPEN ||
					newWs.readyState === WebSocket.CONNECTING
				) {
					newWs.close();
				}
			};

			return cleanup;
		},
		[]
	);

	// Clean up WebSocket connection when the provider unmounts
	useEffect(() => {
		return () => {
			if (ws) {
				console.log("Provider unmounting, closing WebSocket");
				ws.close();
			}
		};
	}, [ws]);

	return (
		<WebSocketContext.Provider value={{ ws, initializeWebSocket }}>
			{children}
		</WebSocketContext.Provider>
	);
};

export const useWebSocket = () => {
	return useContext(WebSocketContext);
};
