import { useState, FC, Fragment } from "react";
import {
	Transition,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle,
} from "@headlessui/react";
import GameRules from "components/partials/gameRules";

interface InfoBoxProps {
	infoKey: string;
}

const InfoBox: FC<InfoBoxProps> = ({ infoKey }) => {
	const [isOpen, setIsOpen] = useState(false);

	const infoContent: { [key: string]: React.ReactNode } = {
		home: (
			<>
				<DialogTitle
					as="h2"
					className="text-2xl text-center max-w-[300px] mx-auto"
				>
					Welcome to Big Brain Energy!
				</DialogTitle>
				<div className="p-4 bg-theme-back-4 border rounded-xl border-theme-back flex flex-col gap-4">
					<p>
						Solve any quizz created for the community, or create
						your own.
					</p>
					<p>
						Play solo or with friends by sharing a link with them
						(no login required).
					</p>
					<p>Play, learn, and have fun!</p>
				</div>
			</>
		),
		rulesSolo: <GameRules gameMode="solo" />,
		rulesFriend: <GameRules gameMode="friend" />,
	};

	return (
		<>
			<div
				onMouseEnter={() => setIsOpen(true)}
				onClick={() => setIsOpen(!isOpen)}
			>
				<svg
					width="20px"
					height="20px"
					viewBox={`0 0 20 20`}
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.99984 1.82946C5.03966 1.82946 1.82929 5.03982 1.82929 9C1.82929 12.9602 5.03966 16.1705 8.99984 16.1705C12.96 16.1705 16.1704 12.9602 16.1704 9C16.1704 5.03982 12.96 1.82946 8.99984 1.82946ZM0.666504 9C0.666504 4.39763 4.39746 0.666667 8.99984 0.666667C13.6022 0.666667 17.3332 4.39763 17.3332 9C17.3332 13.6024 13.6022 17.3333 8.99984 17.3333C4.39746 17.3333 0.666504 13.6024 0.666504 9ZM9.39653 5.07862C9.63519 5.29342 9.65454 5.66103 9.43974 5.8997L9.43198 5.90832C9.21718 6.14698 8.84957 6.16633 8.6109 5.95153C8.37224 5.73672 8.35289 5.36911 8.56769 5.13045L8.57544 5.12183C8.79025 4.88316 9.15786 4.86382 9.39653 5.07862ZM8.99984 8.03101C9.32093 8.03101 9.58123 8.29131 9.58123 8.6124V12.4884C9.58123 12.8095 9.32093 13.0698 8.99984 13.0698C8.67874 13.0698 8.41844 12.8095 8.41844 12.4884V8.6124C8.41844 8.29131 8.67874 8.03101 8.99984 8.03101Z"
						fill="#DB28A9"
					/>
				</svg>
			</div>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={() => setIsOpen(false)}
				>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</TransitionChild>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex items-center justify-center min-h-full p-4 text-center">
							<TransitionChild
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<DialogPanel className="w-full gap-6 flex flex-col max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									{infoContent[infoKey]}
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default InfoBox;
