import { useState } from "react";
import LoginModal from "./loginModal";
import OnboardModal from "./onboardModal";
import { useUserContext } from "contexts/authContext";
// import { claimLocalGamesOnLogin } from "utils/gameService";

interface AuthFlowProps {
	onAuthSuccess: (user: AuthUser) => void;
	onAuthFail: () => void;
}

const AuthFlow: React.FC<AuthFlowProps> = ({ onAuthSuccess, onAuthFail }) => {
	const [showLoginModal, setShowLoginModal] = useState(true);
	const [showOnboardModal, setShowOnboardModal] = useState(false);
	const { user, setUser } = useUserContext();
	const [oldUserId] = useState(user!.gameUser.id);

	const handleLoginSuccess = async (user: AuthUser) => {
		sessionStorage.setItem("user", JSON.stringify(user));
		setUser(user);
		if (user.gameUser.username.startsWith("User")) {
			setShowLoginModal(false);
			setShowOnboardModal(true);
		} else {
			// load games from local storage
			// await claimLocalGamesOnLogin(
			// 	oldUserId,
			// 	user.gameUser.id,
			// 	user.gameUser.username
			// );
			onAuthSuccess(user);
		}
	};

	const handleOnboardComplete = (newUsername: string) => {
		const updatedUser = {
			...user,
			gameUser: { ...user!.gameUser, username: newUsername },
		};
		sessionStorage.setItem("user", JSON.stringify(updatedUser));
		setUser(updatedUser! as AuthUser);
		onAuthSuccess(updatedUser! as AuthUser);
	};

	const handleLoginFail = () => {
		setShowLoginModal(false);
		onAuthFail();
	};

	return (
		<>
			{showLoginModal && (
				<LoginModal
					onLoginSuccess={handleLoginSuccess}
					onLoginFail={handleLoginFail}
				/>
			)}
			{showOnboardModal && user!.loggedIn && (
				<OnboardModal
					oldUserId={oldUserId}
					newUserId={user!.gameUser.id}
					currentUsername={user!.gameUser.username}
					onComplete={handleOnboardComplete}
				/>
			)}
		</>
	);
};

export default AuthFlow;
