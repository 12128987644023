import { ReactNode, useState } from "react";
import FeatureRequest from "components/modals/featureRequest";

interface LayoutProps {
	children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const [showFeatureRequest, setShowFeatureRequest] =
		useState<boolean>(false);
	return (
		<div className="flex justify-center items-center min-h-screen bg-theme-back-4">
			<div className="w-full max-w-[768px] h-full md:max-h-[calc(95vh)] desktop-box-shadow md:rounded-lg border-gray-300 overflow-y-auto bg-white z-0">
				{/* <div className="hidden md:block flex justify-center absolute top-0 right-[46%]">
					<span
						className="text-text-secondary text-sm underline cursor-pointer"
						onClick={() => setShowFeatureRequest(true)}
					>
						Leave Beta feedback
					</span>
				</div> */}
				<div className="flex flex-col h-full relative">{children}</div>
				{showFeatureRequest && (
					<FeatureRequest
						onClose={() => setShowFeatureRequest(false)}
					/>
				)}
			</div>
		</div>
	);
};

export default Layout;
