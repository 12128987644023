import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
	createBrowserRouter,
	RouterProvider,
	useParams,
	Navigate,
} from "react-router-dom";
import { GameProvider } from "contexts/gameContext";
import { UserProvider } from "contexts/authContext";
import { WebSocketProvider } from "contexts/webSocketContext";
import Home from "./routes/home";
import PlaySolo from "routes/play-solo";
import PlayFriend from "routes/play-friend";
import Multiplayer from "routes/multiplayer";
import Create from "routes/create";
import Layout from "components/partials/layout";
import Account from "routes/account";
import Welcome from "routes/welcome";
import { init as ampInit } from "@amplitude/analytics-browser";

interface RouteProps {
	element: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ element: Component }) => {
	return (
		<Layout>
			<Component />
		</Layout>
	);
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to="/welcome" replace />,
	},
	{
		path: "/welcome",
		element: <Route element={Welcome} />,
	},
	// {
	// 	path: "/play",
	// 	element: <Route element={PlaySolo} />,
	// },
	// {
	// 	path: "/play-friend",
	// 	element: (
	// 		<WebSocketProvider>
	// 			<Route element={PlayFriend} />
	// 		</WebSocketProvider>
	// 	),
	// },
	{
		path: "/play-friend/:id",
		element: (
			<WebSocketProvider>
				<Route element={PlayFriendWithId} />
			</WebSocketProvider>
		),
	},
	// {
	// 	path: "/multiplayer",
	// 	element: (
	// 		<WebSocketProvider>
	// 			<Route element={Multiplayer} />
	// 		</WebSocketProvider>
	// 	),
	// },
	// {
	// 	path: "/create",
	// 	element: <Route element={Create} />,
	// },
	// {
	// 	path: "/account",
	// 	element: <Route element={Account} />,
	// },
]);

const environment = process.env.REACT_APP_AMPLITUDE_ENV;
const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || "";

if (environment === "prod") {
	ampInit(amplitudeApiKey, undefined, {
		defaultTracking: {
			pageViews: true,
			sessions: true,
			formInteractions: true,
			fileDownloads: false,
		},
	});
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
	<React.StrictMode>
		<UserProvider>
			<GameProvider>
				<RouterProvider router={router} />
			</GameProvider>
		</UserProvider>
	</React.StrictMode>
);

function PlayFriendWithId() {
	const { id } = useParams();
	return <PlayFriend paramGameId={id} />;
}
