import { FC, Fragment, useState } from "react";
import {
	Transition,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle,
} from "@headlessui/react";

interface FeatureRequestProps {
	onClose: () => void;
}

const FeatureRequest: FC<FeatureRequestProps> = ({ onClose }) => {
	const [feedback, setFeedback] = useState<string>("");
	const submitUrl = "https://submit-form.com/HbdR9DSU2";

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const formData = {
			feedback,
		};

		try {
			await fetch(submitUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(formData),
			});

			onClose();
		} catch (error) {
			onClose();
		}
	};

	return (
		<Transition appear show={true} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</TransitionChild>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<DialogPanel className="flex flex-col gap-6 w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<DialogTitle
									as="h2"
									className="text-2xl text-center max-w-[300px] mx-auto"
								>
									Have an idea for Big Brain Energy?
								</DialogTitle>
								<div className="p-4 bg-theme-back-4 border rounded-xl border-theme-back flex flex-col gap-2">
									<p>
										Thank you for trying out our beta
										version. Please leave any feedback or
										ideas in the box below. This goes
										straight to our development team!
									</p>
									<p>
										If you'd like to get notified of app
										launch, you can do so{" "}
										<a
											href="https://bbenergy.app/play-web"
											target="_blank"
											rel="noreferrer"
										>
											here.
										</a>
									</p>
								</div>
								<form
									onSubmit={handleSubmit}
									className="space-y-4"
								>
									<div>
										<textarea
											id="feedback"
											name="feedback"
											value={feedback}
											onChange={(e) =>
												setFeedback(e.target.value)
											}
											placeholder="Your feeedback or idea here..."
											className="w-full p-2 border border-gray-300 rounded-md"
										/>
									</div>
									<button
										type="submit"
										className="w-full primary px-2 py-4"
									>
										Submit
									</button>
								</form>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default FeatureRequest;
