interface GameRulesProps {
	gameMode: string;
}

const GameRules: React.FC<GameRulesProps> = ({ gameMode }) => {
	const rules = [
		"10 questions",
		"10 seconds per question",
		"Questions get harder 😈",
	];

	if (gameMode !== "solo") {
		rules.push("Whoever answers the most questions wins");
		rules.push("Sudden death if draw after 10");
	}

	return (
		<div className="font-semibold p-4 bg-theme-back-4 border rounded-xl border-theme-back flex flex-col gap-2">
			<h2 className="text-xl font-semibold mb-2">📌 Game Rules</h2>
			<ul className="list-disc list-inside">
				{rules.map((rule, index) => (
					<li key={index} className="mb-1">
						{rule}
					</li>
				))}
			</ul>
		</div>
	);
};

export default GameRules;
