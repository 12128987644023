import { TailSpin } from "react-loader-spinner";

interface LoaderProps {
	message?: string;
}

export default function Loader({ message = "Loading..." }: LoaderProps) {
	return (
		<div className="absolute inset-0 flex flex-col gap-4 justify-center items-center">
			<p className="text-lg">{message}</p>
			<TailSpin
				visible={true}
				height="80"
				width="80"
				color="#DB28A9"
				ariaLabel="tail-spin-loading"
				radius="1"
				wrapperStyle={{}}
				wrapperClass=""
			/>
		</div>
	);
}
