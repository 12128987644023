import { FC, Fragment, useState } from "react";
import {
	Transition,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle,
} from "@headlessui/react";
import { track as ampTrack } from "@amplitude/analytics-browser";

interface ComingSoonProps {
	onClose: () => void;
}

const ComingSoon: FC<ComingSoonProps> = ({ onClose }) => {
	const [enjoyingSubmitted, setEnjoyingSubmitted] = useState<boolean>(false);
	const [appChoiceSubmitted, setAppChoiceSubmitted] =
		useState<boolean>(false);

	return (
		<Transition appear show={true} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</TransitionChild>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<DialogPanel className="flex flex-col gap-6 w-full max-w-md min-h-[400px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								{!enjoyingSubmitted && (
									<>
										<DialogTitle
											as="h2"
											className="text-2xl text-center max-w-[300px] mx-auto"
										>
											Are you enjoying Big Brain Energy?
										</DialogTitle>
										<div className="p-4 bg-theme-back-4 border rounded-xl border-theme-back flex flex-col gap-2">
											<p>
												Thank you for trying out our
												beta version. We're polishing
												everything up and would love to
												know what you think 🙌
											</p>
										</div>
										<div className="flex justify-center gap-4">
											<button
												className="px-4 py-2 secondary"
												onClick={() => {
													ampTrack(
														"feedback_enjoying",
														{
															enjoying: false,
														}
													);
													localStorage.setItem(
														"feedback",
														"submitted"
													);
													onClose();
												}}
											>
												No
											</button>
											<button
												className="px-4 py-2 primary"
												onClick={() => {
													ampTrack(
														"feedback_enjoying",
														{
															enjoying: true,
														}
													);
													localStorage.setItem(
														"feedback",
														"submitted"
													);
													setEnjoyingSubmitted(true);
												}}
											>
												Yes
											</button>
										</div>
									</>
								)}
								{enjoyingSubmitted && !appChoiceSubmitted && (
									<>
										<DialogTitle
											as="h2"
											className="text-2xl text-center max-w-[300px] mx-auto"
										>
											Would you download the app?
										</DialogTitle>
										<div className="p-4 bg-theme-back-4 border rounded-xl border-theme-back flex flex-col gap-2">
											<p>
												Our mobile app is coming very
												soon! Would you download it when
												it's ready?
											</p>
										</div>
										<div className="flex justify-center gap-4">
											<button
												className="px-4 py-2 secondary"
												onClick={() => {
													ampTrack(
														"feedback_app_choice",
														{
															app_choice: "No",
														}
													);
													onClose();
												}}
											>
												No
											</button>
											<button
												className="px-4 py-2 primary"
												onClick={() => {
													ampTrack(
														"feedback_app_choice",
														{
															app_choice:
																"iphone",
														}
													);
													setAppChoiceSubmitted(true);
												}}
											>
												iPhone
											</button>
											<button
												className="px-4 py-2 primary"
												onClick={() => {
													ampTrack(
														"feedback_app_choice",
														{
															app_choice:
																"android",
														}
													);
													setAppChoiceSubmitted(true);
												}}
											>
												Android
											</button>
										</div>
									</>
								)}
								{enjoyingSubmitted && appChoiceSubmitted && (
									<>
										<DialogTitle
											as="h2"
											className="text-2xl text-center max-w-[300px] mx-auto"
										>
											Would you like to get notified when
											the app is live?
										</DialogTitle>
										<div className="p-4 bg-theme-back-4 border rounded-xl border-theme-back flex flex-col gap-2">
											<p>
												Thank you for trying out our
												beta version and your feedback.
												If you'd like, we can ping your
												email when the app is live!
											</p>
											<p>
												Don't worry, we won't use your
												e-mail for anything else.
											</p>
										</div>
										<a
											href="https://bbenergy.app/play-web"
											target="_blank"
											rel="noreferrer"
											className="w-full primary px-2 py-4"
										>
											Get notified of app launch
										</a>
									</>
								)}
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ComingSoon;
