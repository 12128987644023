import { useState } from "react";

interface SendInviteProps {
	gameId: string;
}

const SendInvite: React.FC<SendInviteProps> = ({ gameId }) => {
	const [copied, setCopied] = useState(false);

	const copyLinkToClipboard = () => {
		const link = `${window.location.href}/${gameId}`;
		navigator.clipboard.writeText(link).then(() => {
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 3000); // 3000 milliseconds = 3 seconds
		});
	};

	return (
		<div className="p-4 rounded-3xl border-1 bg-theme-back-3 border border-theme-brand-purple">
			<p className="text-center mb-1 text-base font-semibold">
				🔗 Send this link to your friend
			</p>
			<p className="text-sm text-center mb-4 text-text-secondary">
				Don't worry, they won't need to log in to play
			</p>
			<div>
				<p
					className="text-center text-text-blue cursor-pointer"
					onClick={copyLinkToClipboard}
				>
					{window.location.href}/{gameId}
				</p>
				<button
					className="mt-4 px-4 py-2 rounded primary"
					onClick={copyLinkToClipboard}
				>
					{copied ? "Copied" : "Copy link"}
				</button>
			</div>
		</div>
	);
};

export default SendInvite;
