interface GameIntroProps {
	quizData: QuizDetails;
}

const GameIntro: React.FC<GameIntroProps> = ({ quizData }) => {
	const emoji = quizData.emoji || "🌙";
	return (
		<div className="text-center">
			{/* emoji needs to be category emoji */}
			<h1 className="text-2xl font-bold mb-4">
				{emoji} Welcome to "{quizData.title}"
			</h1>
			<p className="text-base mb-2">{quizData.intro}</p>
		</div>
	);
};

export default GameIntro;
