interface AcceptInviteProps {
	markReady: () => void;
}

export default function AcceptInvite({ markReady }: AcceptInviteProps) {
	return (
		<button className="px-4 py-2 w-full primary" onClick={markReady}>
			Ready
		</button>
	);
}
