import { createClient } from "@supabase/supabase-js";

export const supabase = createClient(
	process.env.REACT_APP_SUPABASE_URL ?? "",
	process.env.REACT_APP_SUPABASE_ANON_KEY ?? ""
);

const toSnakeCase = (str: string) => {
	return str.replace(/([A-Z])/g, (letter) => `_${letter.toLowerCase()}`);
};

const topKeysToSnakeCase = (obj: any) => {
	if (obj !== null && obj.constructor === Object) {
		return Object.keys(obj).reduce((result, key) => {
			// @ts-ignore
			result[toSnakeCase(key)] = obj[key];
			return result;
		}, {});
	}
	return obj;
};

const toCamelCase = (str: string) => {
	return str.replace(/([-_][a-z])/g, (group) =>
		group.toUpperCase().replace("-", "").replace("_", "")
	);
};

const topKeysToCamelCase = (obj: any) => {
	if (obj !== null && obj.constructor === Object) {
		return Object.keys(obj).reduce((result, key) => {
			// @ts-ignore
			result[toCamelCase(key)] = obj[key];
			return result;
		}, {});
	}
	return obj;
};

export const createGame = async (newGame: Game) => {
	const newGameSnakeCase = topKeysToSnakeCase(newGame);

	const { data, error } = await supabase
		.from("Games")
		.insert([newGameSnakeCase])
		.select();

	if (error) {
		console.error("Error creating new game in Supabase:", error);
		throw error;
	}

	newGame.id = data[0].id;
	console.log("created new game in supabase");
	return newGame;
};

export const markReady = async (gameId: string, user: GameUser) => {
	console.log("marking ready");
	const gameData = await loadGameFromId(gameId);
	const updatedPlayers = [...gameData.players, user];
	const updatedPlayerIds = gameData.playerIds + "," + user.id;
	const updatedPlayerData = [
		...gameData.playerData,
		{
			id: user.id,
			ready: true,
			currentScore: 0,
		},
	];
	const updatedGameData: Game = {
		...gameData,
		players: updatedPlayers,
		playerData: updatedPlayerData,
		playerIds: updatedPlayerIds,
	};
	// set game startTime 6 seconds from now in number format
	updatedGameData.startTime = Date.now() + 6000;
	updatedGameData.gameStatus = "in_progress" as GameStatus;
	await overwriteGameOnSupabase(updatedGameData);
	console.log("marked player as ready in supabase");
	return updatedGameData;
};

export const overwriteGameOnSupabase = async (game: Game) => {
	console.log("overwriteGameOnSupabase");

	const gameSnakeCase = topKeysToSnakeCase(game);
	const { data, error } = await supabase
		.from("Games")
		.upsert(gameSnakeCase)
		.select();
	if (error) {
		console.error("Error overwriting game on Supabase:", error);
		throw error;
	}

	console.log("overwrote game on supabase");

	return game;
};

export const incrementUserScore = async (
	id: string,
	newLocalScore: number,
	addScore: number
) => {
	console.log("incrementUserScore");
	const { data: user, error } = await supabase
		.from("Users")
		.select("all_time_answers,id")
		.eq("id", id)
		.single();

	if (error) {
		console.error("Error fetching user from Supabase:", error);
		return;
	}

	if (user) {
		const updatedScore = user.all_time_answers + addScore;

		// Update user score in Supabase
		const { data, error: updateError } = await supabase
			.from("Users")
			.update({ all_time_answers: Math.max(updatedScore, newLocalScore) })
			.eq("id", id);

		if (updateError) {
			console.error(
				"Error updating user score in Supabase:",
				updateError
			);
			return;
		}

		console.log("Incremented user score in Supabase");
	} else {
		console.log("User does not exist");
	}
};

export const loadGameFromId = async (gameId: string) => {
	try {
		const { data, error } = await supabase
			.from("Games")
			.select("*")
			.eq("id", gameId)
			.single();

		if (error) {
			console.error("Error loading game:", error);
			throw Error("Error loading game");
		}

		if (data) {
			const gameData = topKeysToCamelCase(data);
			return gameData as Game;
		} else {
			throw Error("Game does not exist");
		}
	} catch (error) {
		console.error("Error loading game:", error);
		throw Error("Error loading game");
	}
};

export const loadQuizFromId = async (quizId: string) => {
	try {
		const { data, error } = await supabase
			.from("Quizzes")
			.select("*")
			.eq("id", quizId)
			.single();

		if (error) {
			console.error("Error loading game:", error);
			return null;
		}

		if (data) {
			const gameData = topKeysToCamelCase(data);
			return gameData as QuizDetails;
		} else {
			console.log("game does not exist");
			return null;
		}
	} catch (error) {
		console.error("Error loading game:", error);
		return null;
	}
};

export const fetchCompletedGames = async (id: string) => {
	console.log("fetchCompletedGames");
	console.log(id);
	const { data, error } = await supabase
		.from("Games")
		.select()
		.eq("game_status", "completed")
		.ilike("player_ids", `%${id}%`);

	if (error) {
		console.error("Error fetching completed games from Supabase:", error);
		return [];
	}

	if (data) {
		const games = data.map((game) => {
			return topKeysToCamelCase(game) as Game;
		});
		return games;
	} else {
		return [];
	}
};

export const fetchUserCreatedQuizzes = async (user: AuthUser) => {
	console.log("fetchUserCreatedQuizzes");
	const { data, error } = await supabase
		.from("Quizzes")
		.select("*")
		.eq("user_id", user.gameUser.id);

	if (error) {
		console.error(
			"Error fetching user created quizzes from Supabase:",
			error
		);
		return [];
	}

	if (data) {
		const quizzes = data.map((quiz) => {
			return topKeysToCamelCase(quiz) as QuizDetails;
		});
		return quizzes;
	} else {
		return [];
	}
};

export const fetchOrCreateUser = async (userId: string, oldUser: AuthUser) => {
	console.log("fetchOrCreateUser");
	let gameUser = null as GameUser | null;
	let signUp = false;

	// fetch
	console.log("loading user");
	const { data, error } = await supabase
		.from("Users")
		.select("*")
		.eq("id", userId)
		.maybeSingle();

	if (data !== null) {
		gameUser = topKeysToCamelCase(data) as GameUser;
	}

	if (data === null) {
		// create
		signUp = true;
		console.log("Signing up user");
		gameUser = oldUser.gameUser;
		gameUser.id = userId;
		const gameUserSnakeCase = topKeysToSnakeCase(gameUser);

		const { data, error: er } = await supabase
			.from("Users")
			.insert([gameUserSnakeCase])
			.select();

		if (er) {
			console.error("Error creating new user in Supabase:", er);
			throw er;
		}
	}
	return { user: gameUser, signUp: signUp };
};

export const changeUsername = async (
	userId: string,
	oldUsername: string,
	newUsername: string
) => {
	const returnObj = { error: null as string | null };

	// validations
	if (newUsername.trim() === "") {
		returnObj.error = "Username cannot be empty";
		return returnObj;
	}

	if (newUsername === oldUsername) {
		returnObj.error = "Username cannot be the same as before";
		return returnObj;
	}

	if (newUsername.length > 20) {
		returnObj.error = "Username cannot be longer than 20 characters";
		return returnObj;
	}

	if (newUsername.length < 3) {
		returnObj.error = "Username cannot be shorter than 3 characters";
		return returnObj;
	}

	if (!newUsername.match(/^[a-zA-Z0-9_]*$/)) {
		returnObj.error = "Username can only contain letters, numbers, and _";
		return returnObj;
	}

	// Update username in Supabase
	const { data, error } = await supabase
		.from("Users")
		.update({ username: newUsername })
		.eq("id", userId);

	if (error) {
		console.error("Error updating username in Supabase:", error);
		if (
			error.message.includes(
				"duplicate key value violates unique constraint"
			)
		) {
			returnObj.error = "Username already exists";
		} else {
			returnObj.error = "Error updating username";
		}
		return returnObj;
	}

	console.log("Updated username in Supabase");
	return returnObj;
};

// take all games in 'playedGames' in async storage and change the old user id to new user id
export const claimLocalGamesOnSignUp = async (
	oldUserId: string,
	newUserId: string,
	newUsername: string
) => {
	console.log("claimLocalGamesOnSignUp");

	const playedGameIds = localStorage.getItem("playedGames");
	console.log(playedGameIds);
	if (!playedGameIds) {
		return;
	}

	const parsedPlayedGameIds = JSON.parse(playedGameIds) as string[];
	if (!parsedPlayedGameIds || parsedPlayedGameIds.length === 0) {
		return;
	}

	const playedGames = await fetchGamesByIds(parsedPlayedGameIds);
	if (playedGames.length === 0) {
		return;
	}

	console.log("have games to claim");

	const newPlayedGames = playedGames?.map((game: any) => {
		game = changeUserInGameData(game, oldUserId, newUserId, newUsername);
		return game;
	});

	await updatePlayedGamesOnSupa(newPlayedGames);
	localStorage.removeItem("playedGames");
};

const fetchGamesByIds = async (gameIds: string[]) => {
	console.log("fetchGamesByIds");
	const { data, error } = await supabase
		.from("Games")
		.select()
		.eq("game_status", "completed")
		.in("id", gameIds);

	console.log(data, error);

	if (error) {
		console.error("Error fetching completed games from Supabase:", error);
		return [];
	}

	if (data) {
		const games = data.map((game) => {
			return topKeysToCamelCase(game) as Game;
		});
		return games;
	} else {
		return [];
	}
};

const updatePlayedGamesOnSupa = async (games: Game[]) => {
	console.log("updatePlayedGamesOnSupa");
	const gamesSnakeCase = games.map((game) => {
		return topKeysToSnakeCase(game);
	});

	const { data, error } = await supabase.from("Games").upsert(gamesSnakeCase);

	if (error) {
		console.error("Error updating played games on Supabase:", error);
		throw error;
	}

	console.log("Updated played games on Supabase");
};

const changeUserInGameData = (
	game: Game,
	oldUserId: string,
	newUserId: string,
	newUsername: string
): Game => {
	console.log("changeUserInGameData");
	const updatedPlayers = game.players.map((player) => {
		if (String(player.id) === String(oldUserId)) {
			return { ...player, id: newUserId, username: newUsername };
		}
		return player;
	});

	const updatedPlayerData = game.playerData.map((player) => {
		if (String(player.id) === String(oldUserId)) {
			return { ...player, id: newUserId };
		}
		return player;
	});

	const updatedQuestionData = game.questions.map((question) => {
		const updatedPlayerAnswers = question.playerAnswers?.map(
			(playerAnswer) => {
				if (String(playerAnswer.playerId) === String(oldUserId)) {
					return { ...playerAnswer, playerId: newUserId };
				}
				return playerAnswer;
			}
		);
		return { ...question, playerAnswers: updatedPlayerAnswers };
	});

	const playerIds = game.playerIds.replace(oldUserId, newUserId);

	const data = {
		...game,
		players: updatedPlayers,
		playerData: updatedPlayerData,
		questions: updatedQuestionData,
		playerIds: playerIds,
	} as Game;
	return data;
};

export const selectOnlineQuiz = async (
	category: string,
	userOneId: string,
	userTwoId: string
) => {
	// Fetch all games where userOneId or userTwoId is in player_ids
	const { data: games, error: gamesError } = await supabase
		.from("Games")
		.select("quiz_id")
		.or(`player_ids.ilike.%${userOneId}%,player_ids.ilike.%${userTwoId}%`);

	if (gamesError) {
		console.error("Error fetching games:", gamesError);
		throw Error;
	}

	const quizIds = games.map((game) => game.quiz_id);
	console.log("quizIds", quizIds);

	// Fetch quizzes based on the specified category and not in the quizIds
	let quizzesQuery = supabase
		.from("Quizzes")
		.select("*")
		.not("id", "in", `(${quizIds.join(",")})`)
		.limit(30);

	if (category && category !== "all") {
		console.log("category", category);
		quizzesQuery = quizzesQuery.eq("category", category);
	}

	const { data, error: quizzesError } = await quizzesQuery;

	if (quizzesError) {
		console.error("Error fetching quizzes:", quizzesError);
		throw Error;
	}

	if (data && data.length > 0) {
		const quiz = data[Math.floor(Math.random() * data.length)];
		console.log("quiz", quiz);
		return topKeysToCamelCase(quiz) as QuizDetails;
	} else {
		throw Error;
	}
};
