import {
	createGame as createGameSupa,
	overwriteGameOnSupabase,
} from "./supabase";

// Fetch Quizzes
export const getQuizzes = async (
	seen: string[],
	selectedCategory: string,
	page: number = 1
) => {
	const data = { data: <QuizDetails[]>[], totalPages: 0 };

	const resp = await fetchQuizzesOnline(seen, selectedCategory, page);
	data.totalPages = resp["total_pages"];
	data.data = resp["data"];
	return data;
};

const fetchQuizzesOnline = async (
	seen: string[],
	selectedCategory: string,
	page: number = 1
) => {
	console.log("fetchQuizzesOnline");
	const params: any = {
		page,
		seen: seen,
	};
	if (selectedCategory && selectedCategory !== "") {
		params.category = selectedCategory;
	}

	const queryString = new URLSearchParams(params).toString();
	const response = await fetch(
		`${process.env.REACT_APP_API_URL}/quizzes?${queryString}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}
	);

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	const data = await response.json();
	data["data"] = data["data"] as QuizDetails[];
	return data;
};

// Create Game
export const createGame = async (
	user: GameUser,
	quizData: QuizDetails,
	gameMode: GameMode
) => {
	let game = buildGame(user, quizData, gameMode);

	game = await createGameSupa(game);

	return game;
};

const buildGame = (
	user: GameUser,
	quizData: QuizDetails,
	gameMode: GameMode
) => {
	let gameStatus: GameStatus;
	let playerCount: number;
	if (gameMode === "solo") {
		gameStatus = "in_progress";
		playerCount = 1;
	} else {
		gameStatus = "waiting";
		playerCount = 2;
	}
	const newGame: Game = {
		quizId: quizData.id!,
		quizTitle: quizData.title,
		playerCount: playerCount,
		gameMode: gameMode,
		gameStatus: gameStatus,
		currentRound: 0,
		players: [user],
		playerIds: user.id,
		playerData: [
			{
				id: user.id,
				ready: true,
				currentScore: 0,
			},
		],
		questions: quizData.questions.map((question) => ({
			...question,
			playerAnswers: [],
		})),
	};

	return newGame;
};
