import { useState, Fragment } from "react";
// import { changeUsername } from "utils/gameService";
import {
	Dialog,
	DialogPanel,
	DialogTitle,
	Transition,
	TransitionChild,
} from "@headlessui/react";

interface OnboardModalProps {
	oldUserId: string;
	newUserId: string;
	currentUsername: string;
	onComplete: (newUsername: string) => void;
}

const OnboardModal: React.FC<OnboardModalProps> = ({
	oldUserId,
	newUserId,
	currentUsername,
	onComplete,
}) => {
	console.log(oldUserId, newUserId, currentUsername, onComplete);
	const [username, setUsername] = useState(currentUsername);
	const [error, setError] = useState<string | null>(null);

	const handleSave = async () => {
		if (username.trim() === "") {
			setError("Username cannot be empty");
		} else {
			// const result = await changeUsername(
			// 	oldUserId,
			// 	newUserId,
			// 	currentUsername,
			// 	username
			// );
			// if (result.error) {
			// 	setError(result.error);
			// } else {
			// 	onComplete(username);
			// }
		}
	};

	return (
		<Transition appear show={true} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => {}}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</TransitionChild>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<DialogTitle
									as="h2"
									className="text-lg font-medium leading-6 text-gray-900"
								>
									Set Your Username
								</DialogTitle>
								<div className="mt-4">
									{error && (
										<p className="mb-4 text-sm text-red-600">
											{error}
										</p>
									)}
									<input
										type="text"
										value={username}
										onChange={(e) =>
											setUsername(e.target.value)
										}
										className="w-full p-2 border border-gray-300 rounded mt-2"
									/>
									<button
										onClick={handleSave}
										className="mt-4 w-full py-2 px-4 primary"
									>
										Save
									</button>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default OnboardModal;
