interface PlayerStatesProps {
	game: Game;
}
const PlayerStates: React.FC<PlayerStatesProps> = ({ game }) => {
	return (
		<div className="flex justify-between items-center">
			<div className="flex items-center gap-3">
				<div className="flex items-center space-x-1 cursor-pointer">
					<div className="w-[30px] h-[30px] bg-gray-300 text-white flex items-center justify-center rounded-full text-xs">
						{game.players[0].username.charAt(0).toUpperCase()}
					</div>
					<span className="font-medium">
						{game.players[0].username}
					</span>
				</div>
				<p className="font-semibold">
					{game.playerData[0].ready ? "Ready" : "Waiting"}
				</p>
			</div>
			<div className="flex items-center gap-3">
				<div className="flex items-center space-x-1 cursor-pointer">
					<div className="w-[30px] h-[30px] bg-gray-300 text-white flex items-center justify-center rounded-full text-xs">
						{game.players[1]?.username.charAt(0).toUpperCase() ||
							"P"}
					</div>
					<span>{game.players[1]?.username || "Player 2"}</span>
				</div>
				<p className="font-semibold">
					{game.playerData[1]?.ready ? "Ready" : "Waiting"}
				</p>
			</div>
		</div>
	);
};

export default PlayerStates;
