export const setAnswerInGameRef = (
	gameRef: React.MutableRefObject<Game | null>,
	answerIndex: number,
	currentQuestionIndex: number,
	userId: string,
	callbackFn: () => void
) => {
	let playerAnswers: PlayerAnswer[] =
		gameRef.current?.questions[currentQuestionIndex].playerAnswers || [];
	// update gameRef with player answers
	playerAnswers.push({ playerId: userId, answerIndex: answerIndex });
	callbackFn();
};

export const setScoresInGameRef = (
	gameRef: React.MutableRefObject<Game | null>,
	currentQuestionIndex: number
) => {
	const playerAnswers =
		gameRef.current?.questions[currentQuestionIndex].playerAnswers || [];
	const correctIndex =
		gameRef.current?.questions[currentQuestionIndex].correctIndex;

	playerAnswers.forEach((playerAnswer) => {
		const player = gameRef.current?.playerData.find(
			(playerData) => playerData.id === playerAnswer.playerId
		);
		if (player) {
			const isCorrect = playerAnswer.answerIndex === correctIndex;
			player.currentScore = isCorrect
				? player.currentScore + 1
				: player.currentScore;
		}
	});
	gameRef.current!.currentRound = currentQuestionIndex + 1;
};
