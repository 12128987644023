import { useEffect } from "react";
import { useGameContext } from "contexts/gameContext";
import { useUserContext } from "contexts/authContext";
import { overwriteGameOnSupabase, incrementUserScore } from "utils/supabase";
import { track as ampTrack } from "@amplitude/analytics-browser";

interface useCompleteGameProps {
	writeGameToSupabase: boolean;
	gameRef: React.MutableRefObject<Game | null>;
	gameCompletedRef: React.MutableRefObject<boolean>;
	isGameCompleted: boolean;
	endGame: (game: Game) => void;
}

export default function useCompleteGame({
	writeGameToSupabase,
	gameRef,
	gameCompletedRef,
	isGameCompleted,
	endGame,
}: useCompleteGameProps) {
	const { setActiveGame, setActiveQuiz, markGameAsPlayed, markQuizAsSeen } =
		useGameContext();
	const { user: authUser, setUser } = useUserContext();
	useEffect(() => {
		const completeGame = (completedGame: Game) => {
			console.log("complete Game");
			markGameAsPlayed(completedGame.id!);
			markQuizAsSeen(completedGame.quizId);
			setActiveGame(null);
			setActiveQuiz(null);
			const gameScore =
				completedGame.playerData.find(
					(player) => player.id === authUser!.gameUser.id
				)?.currentScore || 0;

			const newScore = authUser!.gameUser.allTimeAnswers + gameScore;

			setUser({
				...authUser!,
				gameUser: {
					...authUser!.gameUser,
					allTimeAnswers: newScore,
				},
			});
			if (authUser!.loggedIn) {
				incrementUserScore(authUser!.gameUser.id!, newScore, gameScore);
			}
			endGame(completedGame);
		};

		if (gameRef.current && isGameCompleted && !gameCompletedRef.current) {
			gameCompletedRef.current = true;
			gameRef.current.gameStatus = "completed";
			if (writeGameToSupabase) {
				overwriteGameOnSupabase(gameRef.current).then(() => {
					completeGame(gameRef.current!);
				});
				ampTrack("game_completed", { mode: gameRef.current.gameMode });
			} else {
				completeGame(gameRef.current);
			}
		}
	}, [isGameCompleted]);
}
