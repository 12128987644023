import { useEffect } from "react";

interface UseAutoAnswerTimerProps {
	blocked: boolean;
	timer: number | null;
	selectedAnswer: number | null;
	handleAnswerSelect: (answerIndex: number) => void;
	setTimer: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function useAutoAnswerTimer({
	blocked,
	timer,
	selectedAnswer,
	handleAnswerSelect,
	setTimer,
}: UseAutoAnswerTimerProps) {
	useEffect(() => {
		if (blocked) return;
		if (timer === null) return;

		if (timer <= 0.1) {
			if (selectedAnswer == null) {
				console.log("auto-select");
				handleAnswerSelect(-1);
			}

			return;
		}

		const countdown = setInterval(() => {
			setTimer((prev) => (prev === null ? 10 : prev - 0.1));
		}, 100);

		return () => clearInterval(countdown);
	}, [timer, selectedAnswer]);
}
