import { useEffect } from "react";

interface UseTypeQuestionProps {
	blocked: boolean;
	currentQuestion: Question | null;
	isTyping: boolean;
	setDisplayedQuestion: React.Dispatch<React.SetStateAction<string>>;
	setIsTyping: React.Dispatch<React.SetStateAction<boolean>>;
	setIsRevealing: React.Dispatch<React.SetStateAction<boolean>>;
	startTimer: () => void;
}

export default function useTypeQuestion({
	blocked,
	currentQuestion,
	isTyping,
	setDisplayedQuestion,
	setIsTyping,
	setIsRevealing,
	startTimer,
}: UseTypeQuestionProps) {
	useEffect(() => {
		if (blocked) return;
		if (currentQuestion && isTyping) {
			setDisplayedQuestion("");
			let charIndex = 0;
			let questionTimer: ReturnType<typeof setTimeout>;

			const typeQuestion = () => {
				if (charIndex < currentQuestion.question.length) {
					charIndex++;
					setDisplayedQuestion((prev) => {
						return prev + currentQuestion.question[charIndex - 1];
					});
					questionTimer = setTimeout(typeQuestion, 40);
				} else {
					setIsTyping(false);
					setIsRevealing(true);
					clearTimeout(questionTimer);
					startTimer();
				}
			};

			typeQuestion();

			return () => clearTimeout(questionTimer);
		}
		return undefined;
	}, [currentQuestion, isTyping, blocked]);
}
