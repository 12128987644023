import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "contexts/authContext";
import Feedback from "components/modals/feedback";
import Loader from "components/partials/loader";
import AuthFlow from "components/modals/authFlow";

interface MultiplayerEndProps {
	game: Game;
}

const MultiplayerEnd: React.FC<MultiplayerEndProps> = ({ game }) => {
	const [outcome, setOucome] = useState<string | null>(null);
	const [userScore, setUserScore] = useState<number | null>(null);
	const [yourUsername, setYourUsername] = useState<string | null>(null);
	const [otherPlayerUsername, setOtherPlayerUsername] = useState<
		string | null
	>(null);
	const [otherPlayerScore, setOtherPlayerScore] = useState<number | null>(
		null
	);
	const { user } = useUserContext();

	const [winner, setWinner] = useState<boolean | null>(null);
	const [quizLength, setQuizLength] = useState<number | null>(null);

	// modals
	const [showAuthFlow, setShowAuthFlow] = useState(false);
	const handleAuthSuccess = () => {
		setShowAuthFlow(false);
	};

	const handleAuthFail = () => {
		setShowAuthFlow(false);
	};
	const [showFeedback, setShowFeedback] = useState(false);
	// show feedback after 3 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowFeedback(true);
		}, 10000);

		return () => clearTimeout(timer);
	}, []);

	// Set scores and winner when component mounts
	useEffect(() => {
		const userScore = game.playerData.find(
			(player) => player.id === user!.gameUser.id
		)!.currentScore;
		const otherPlayerScore = game.playerData.find(
			(player) => player.id !== user!.gameUser.id
		)!.currentScore;
		let winner = null;
		let outcome = "It's a tie! 🤝";
		if (userScore > otherPlayerScore) {
			outcome = "You won! 🎉";
			winner = true;
		} else if (userScore < otherPlayerScore) {
			outcome = "You lost! 😢";
			winner = false;
		}
		setQuizLength(
			game.questions.filter((q) => q.playerAnswers.length > 0).length
		);
		setUserScore(userScore);
		setOtherPlayerScore(otherPlayerScore);
		setOucome(outcome);
		setWinner(winner);
		setYourUsername(user!.gameUser.username);
		setOtherPlayerUsername(
			game.players.find((player) => player.id !== user!.gameUser.id)
				?.username!
		);
	}, []);

	if (userScore === null || otherPlayerScore === null || !outcome) {
		return <Loader />;
	}

	return (
		<>
			<header className="flex flex-col gap-4 justify-center items-center py-6 mb-2 p-4 backgrounded">
				<h2 className="text-center text-3xl">Game Ended</h2>
				<h2 className="text-center text-2xl">{outcome}</h2>
			</header>
			<main>
				{/* user scores */}
				<div className="flex w-full p-4 justify-between items-center rounded-3xl bg-theme-back-4">
					<div className="flex items-center gap-4">
						<div className="w-[30px] h-[30px] bg-gray-300 text-white flex items-center justify-center rounded-full text-xs">
							{yourUsername!.charAt(0).toUpperCase()}
						</div>
						<div>
							<p className="font-satoshi-semibold">
								{yourUsername}
							</p>
							<p className="text-text-secondary">
								Score: {userScore}/{quizLength}
							</p>
						</div>
					</div>
					{/* <div className="font-satoshi-semibold text-2xl">
						{winner ? "1" : "0"} - {!winner ? "1" : "0"}
					</div> */}
					<div className="flex items-center gap-4">
						<div>
							<p className="font-satoshi-semibold">
								{otherPlayerUsername}
							</p>
							<p className="text-text-secondary text-right">
								Score: {otherPlayerScore}/{quizLength}
							</p>
						</div>
						<div className="w-[30px] h-[30px] bg-gray-300 text-white flex items-center justify-center rounded-full text-xs">
							{otherPlayerUsername!.charAt(0).toUpperCase()}
						</div>
					</div>
				</div>
				{/* next quiz options */}
			</main>
			<footer className="p-4 flex-col gap-2 with-shadow">
				{user && !user.loggedIn && (
					<div>
						<p className="mb-3">
							📌 Sign up for free to save your progress and
							generate your own unique quizzes.
						</p>
						<button
							className="px-4 py-2 primary w-full"
							onClick={() => {
								setShowAuthFlow(true);
							}}
						>
							Make me a unique quizz
						</button>
					</div>
				)}
				<Link to="/" className="px-4 py-2 secondary w-full">
					Back to Quizzes
				</Link>
			</footer>
			{showAuthFlow && (
				<AuthFlow
					onAuthSuccess={handleAuthSuccess}
					onAuthFail={handleAuthFail}
				/>
			)}
			{showFeedback && (
				<Feedback onClose={() => setShowFeedback(false)} />
			)}
		</>
	);
};

export default MultiplayerEnd;
