import {
	createContext,
	useState,
	useEffect,
	useContext,
	useCallback,
	ReactNode,
} from "react";

interface UserContextType {
	user: AuthUser | null;
	loading: boolean;
	setUser: (user: AuthUser) => void;
	refreshToken: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const generateRandomUser = (): AuthUser => {
	const randomId = Math.floor(Math.random() * 1000000).toString();
	return {
		gameUser: {
			id: randomId,
			username: `User${randomId}`,
			allTimeAnswers: 0,
		},
		loggedIn: false,
	};
};

export function UserProvider({ children }: { children: ReactNode }) {
	const [user, setUser] = useState<AuthUser | null>(() => {
		const storedUser = localStorage.getItem("user");
		if (storedUser) {
			return JSON.parse(storedUser) as AuthUser;
		} else {
			const newUser = generateRandomUser();
			localStorage.setItem("user", JSON.stringify(newUser));
			return newUser;
		}
	});
	const [loading, setLoading] = useState<boolean>(true);
	useEffect(() => {
		const loadUser = async () => {
			const storedUser = localStorage.getItem("user");
			if (storedUser) {
				setUser(JSON.parse(storedUser));
			} else {
				const newUser = generateRandomUser();
				localStorage.setItem("user", JSON.stringify(newUser));
				setUser(newUser);
			}
			setLoading(false); // Set loading to false after user is loaded
		};

		loadUser();
	}, []);

	const refreshToken = useCallback(async () => {
		// const auth = getAuth();
		// const currentUser = auth.currentUser;
		// if (currentUser) {
		//   const token = await fetchAuthToken(currentUser);
		//   const updatedUser = {
		//     ...user,
		//     token,
		//     loggedIn: true,
		//   } as AuthUser;
		//   setUser(updatedUser);
		//   await AsyncStorage.setItem("user", JSON.stringify(updatedUser));
		//   console.log("Token refreshed to " + token);
		// } else {
		//   throw Error("No user logged in");
		// }
	}, [user]);

	//   useEffect(() => {
	//     const interval = setInterval(async () => {
	//       if (user && user.loggedIn) {
	//         await refreshToken();
	//       }
	//     }, 30 * 60 * 1000); // 30 minutes

	//     return () => clearInterval(interval);
	//   }, [user, refreshToken]);

	const updateUser = async (newUser: AuthUser) => {
		setUser(newUser);
		localStorage.setItem("user", JSON.stringify(newUser));
	};

	return (
		<UserContext.Provider
			value={{ user, loading, setUser: updateUser, refreshToken }}
		>
			{children}
		</UserContext.Provider>
	);
}

export const useUserContext = (): UserContextType => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("useUserContext must be used within a UserProvider");
	}
	return context;
};
