import { useEffect } from "react";

interface UseRevealAnswerOptionsProps {
	blocked: boolean;
	currentQuestion: Question | null;
	isRevealing: boolean;
	setDisplayedAnswers: React.Dispatch<React.SetStateAction<string[]>>;
	setIsRevealing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function useRevealAnswerOptions({
	blocked,
	currentQuestion,
	isRevealing,
	setDisplayedAnswers,
	setIsRevealing,
}: UseRevealAnswerOptionsProps) {
	useEffect(() => {
		if (blocked) return;
		if (currentQuestion && isRevealing) {
			let answerIndex = 0;
			let answerTimer: ReturnType<typeof setTimeout>;

			const revealAnswers = () => {
				if (answerIndex < currentQuestion.answers.length) {
					setDisplayedAnswers(
						currentQuestion.answers.slice(0, answerIndex + 1)
					);
					answerIndex++;
					answerTimer = setTimeout(revealAnswers, 1000);
				} else {
					setIsRevealing(false);
					clearTimeout(answerTimer);
				}
			};

			revealAnswers();

			return () => clearTimeout(answerTimer);
		}
		return undefined;
	}, [currentQuestion, isRevealing]);
}
