import suddenDeathImg from "../../assets/images/sudden-death-img.png";
import suddenDeathBg from "../../assets/images/sudden-death-bg.png";

const SuddenDeath = () => {
	return (
		<>
			<header className="flex-col mb-2"></header>
			<main>
				<div className="my-8 mx-auto flex flex-col items-center gap-4">
					<h1
						className="font-generalsans-semibold text-center text-3xl"
						style={{
							background:
								"linear-gradient(187deg, #FC7145 45.14%, #605C57 147.79%)",
							backgroundClip: "text",
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
						}}
					>
						Sudden Death Round!
					</h1>
				</div>
				<div className="mt-12">
					<img
						src={suddenDeathImg}
						alt=""
						className="max-w-[300px] md:max-w-[400px] mx-auto"
					/>
				</div>
			</main>
			<footer className="mb-4 bg-transparent">
				<p className="font-generalsans-semibold text-center text-white text-2xl">
					Answer until one of you wins
				</p>
				<img
					src={suddenDeathBg}
					alt=""
					className="absolute bottom-0 left-0 w-full h-auto -z-10"
					style={{ maxHeight: "100%" }}
				/>
			</footer>
		</>
	);
};

export default SuddenDeath;
