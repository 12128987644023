// start game at the same time
// every question:
// - *local* timer 10 seconds to answer
// - when you click answer, you send answer to websocket
// - if you don't click, auto-send -1 after 10 seconds
// - when you have both answers, show answers for 3 seconds
// - if no answer received, stuck on question

import { useState, useEffect, useCallback, useRef } from "react";
import { useGameContext } from "contexts/gameContext";
import { useUserContext } from "contexts/authContext";
import { useWebSocket } from "contexts/webSocketContext";
import useAutoAnswerTimer from "hooks/useGameTimer";
import useTypeQuestion from "hooks/useTypeQuestion";
import useRevealAnswerOptions from "hooks/useRevealAnswers";
import useCompleteGame from "hooks/useCompleteGame";
import { setAnswerInGameRef, setScoresInGameRef } from "utils/gameHelper";
import timerIcon from "assets/images/timer.png";
import InfoBox from "components/modals/infoBox";
import SuddenDeath from "components/partials/suddenDeath";
import Loader from "components/partials/loader";
import { track as ampTrack } from "@amplitude/analytics-browser";

interface MultiplayerFlowProps {
	endGame: (lastGame: Game) => void;
}

const MultiplayerFlow: React.FC<MultiplayerFlowProps> = ({ endGame }) => {
	const { user: authUser } = useUserContext();
	const user = authUser!.gameUser;
	const { activeGame, activeQuiz } = useGameContext();
	const gameRef = useRef<Game>(activeGame!);
	const leaderRef = useRef<boolean>(
		gameRef.current?.players[0].id === user.id
	);
	const otherPlayerUsername = activeGame?.players.find(
		(player) => player.id !== user.id
	)?.username;
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState<Question | null>(
		null
	);
	const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
	const [userScore, setUserScore] = useState<number>(0);
	const [otherPlayerScore, setOtherPlayerScore] = useState<number>(0);
	const [isGameCompleted, setIsGameCompleted] = useState(false);
	const gameCompletedRef = useRef(false);

	const [displayedQuestion, setDisplayedQuestion] = useState<string>("");
	const [displayedAnswers, setDisplayedAnswers] = useState<string[]>([]);
	const [isTyping, setIsTyping] = useState<boolean>(true);
	const [isRevealing, setIsRevealing] = useState<boolean>(false);
	const [isAnswerShow, setIsAnswerShow] = useState<boolean>(false);
	const [otherPlayerAnswerShow, setOtherPlayerAnswerShow] = useState<
		number | any
	>(null);

	const [timer, setTimer] = useState<number | null>(null);
	const [changeQuestion, setChangeQuestion] = useState<boolean>(false);

	// sudden death
	const initQuestions = 2;
	const [quizLength, setQuizLength] = useState<number>(initQuestions);
	const [suddenDeath, setSuddenDeath] = useState<boolean>(false);
	const [suddenDeathIntroShowing, setSuddenDeathIntroShowing] =
		useState<boolean>(false);
	const suddenDeathSeen = useRef<boolean>(false);

	const startTimer = () => {
		setTimer(10);
	};

	// handle answer selection
	const handleAnswerSubmit = (
		val: number,
		indexQuestion: number,
		userId: string
	) => {
		setAnswerInGameRef(gameRef, val, indexQuestion, userId, () => {
			const playerAnswers =
				gameRef.current?.questions[indexQuestion].playerAnswers || [];

			// only move on if there are 2 answers
			if (playerAnswers.length === 2) {
				setIsAnswerShow(true);
				setTimer(null);
				setTimeout(() => {
					setChangeQuestion(true);
					setIsAnswerShow(false);
				}, 3000);
			}
		});
	};

	const handleAnswerSelect = (index: number) => {
		if (selectedAnswer !== null) return;
		setSelectedAnswer(index);
		handleAnswerSubmit(index, currentQuestionIndex, user.id);
		const data = JSON.stringify({
			user: user,
			indexQuestion: currentQuestionIndex,
			answer: index,
		});
		ws!.send(data);
	};

	useEffect(() => {
		if (leaderRef.current) {
			ampTrack("game_in_progress", {
				gameMode: gameRef.current.gameMode,
			});
		}
	}, []);

	// initialize websocket if none in context
	const { ws, initializeWebSocket } = useWebSocket()!;
	useEffect(() => {
		if (!ws && gameRef.current && user) {
			initializeWebSocket(gameRef.current.id!, user.id);
		}
	}, [ws, activeGame]);

	// listen to other player's answer
	useEffect(() => {
		if (!ws) return;

		ws.onmessage = function (event) {
			try {
				const data = JSON.parse(event.data);
				handleAnswerSubmit(
					data.answer,
					data.indexQuestion,
					data.user.id
				);
			} catch (error) {
				console.error(error);
			}
		};

		// Cleanup function
		return () => {
			ws.onmessage = null;
		};
	}, []);

	// set current question
	useEffect(() => {
		if (gameRef.current === null) return;
		setCurrentQuestion(gameRef.current.questions[currentQuestionIndex]);
	}, [currentQuestionIndex]);

	// animation for typing question
	useTypeQuestion({
		blocked: suddenDeathIntroShowing,
		currentQuestion,
		isTyping,
		setDisplayedQuestion,
		setIsTyping,
		setIsRevealing,
		startTimer,
	});

	// auto-submit answer after 10 seconds
	useAutoAnswerTimer({
		blocked: suddenDeathIntroShowing,
		timer,
		selectedAnswer,
		handleAnswerSelect,
		setTimer,
	});

	// animation for revealing answer options
	useRevealAnswerOptions({
		blocked: suddenDeathIntroShowing,
		currentQuestion,
		isRevealing,
		setDisplayedAnswers,
		setIsRevealing,
	});

	// set scores and show answers
	useEffect(() => {
		if (isAnswerShow && gameRef.current) {
			setScoresInGameRef(gameRef, currentQuestionIndex);
			setUserScore(
				gameRef.current.playerData.find(
					(player) => player.id === user.id
				)!.currentScore
			);
			setOtherPlayerScore(
				gameRef.current.playerData.find(
					(player) => player.id !== user.id
				)!.currentScore
			);

			const otherPlayerAnswerToShow = gameRef.current.questions[
				currentQuestionIndex
			].playerAnswers.find(
				(playerAnswer) => playerAnswer.playerId !== user.id
			)?.answerIndex;
			setOtherPlayerAnswerShow(otherPlayerAnswerToShow);
		}
	}, [isAnswerShow]);

	// handleNextQuestion if changeQuestion
	useEffect(() => {
		const handleNextQuestion = async () => {
			if (gameRef.current === null) {
				return;
			}

			setCurrentQuestionIndex((prevIndex) => {
				const newIndex = prevIndex + 1;

				// complete if ran out of questions
				if (newIndex >= gameRef.current!.questions.length) {
					setIsGameCompleted(true);
				}

				// set sudden death if tied at the end of initial questions
				if (
					newIndex >= initQuestions &&
					userScore === otherPlayerScore
				) {
					setSuddenDeath(true);
					setQuizLength((prevLength) => prevLength + 1);
					if (!suddenDeathSeen.current) {
						setSuddenDeathIntroShowing(true);
						setTimeout(() => {
							setSuddenDeathIntroShowing(false);
							suddenDeathSeen.current = true;
						}, 3000);
					}
				}

				// end game once sudden death is broken
				if (
					newIndex >= initQuestions &&
					userScore !== otherPlayerScore
				) {
					setIsGameCompleted(true);
				}

				return newIndex;
			});
			setSelectedAnswer(null);
			setDisplayedQuestion("");
			setDisplayedAnswers([]);
			setIsTyping(true);
			setIsRevealing(false);
			setIsAnswerShow(false);
			setTimer(null);
		};

		if (changeQuestion) {
			handleNextQuestion();
			setChangeQuestion(false);
		}
	}, [changeQuestion]);

	// mark game as completed
	useCompleteGame({
		writeGameToSupabase: leaderRef.current,
		gameRef,
		gameCompletedRef,
		isGameCompleted,
		endGame,
	});

	return (
		<>
			{!suddenDeathIntroShowing && (
				<>
					<header className="flex-col items-center gap-4 pt-8 backgrounded">
						<div className="flex justify-between items-center w-full">
							<div></div>
							<div className="flex items-center gap-2 justify-center text-theme-off-purple bg-theme-off-purple-3 w-fit rounded-xl px-3 py-1">
								<img src={timerIcon} alt="" width="18px" />
								<p className="font-semibold">
									{timer === null
										? 10
										: timer < 0
										? 0
										: Math.floor(timer)}{" "}
									seconds
								</p>
							</div>
							<InfoBox infoKey="rulesFriend" />
						</div>
						<div className="w-full bg-gray-300 h-2">
							<div
								className="brand-gradient-background h-2 rounded-2xl progress-bar"
								style={{
									width: `${
										timer === null
											? 0
											: timer < 0
											? 100
											: ((10 - timer) / 10) * 100
									}%`,
								}}
							/>
						</div>
						{suddenDeath && (
							<div
								className="flex justify-center items-center w-full py-1 rounded-2xl"
								style={{
									background:
										"linear-gradient(90deg, #FB3030 0%, #FF8E3C 100%)",
								}}
							>
								<p className="font-semibold text-white text-center">
									🔥 Sudden Death Round!
								</p>
							</div>
						)}
						<div className="flex justify-between items-center w-full">
							<div className="flex flex-col justify-start gap-2">
								<div className="flex items-center space-x-1">
									<div className="w-[30px] h-[30px] bg-gray-300 text-white flex items-center justify-center rounded-full text-xs">
										{user.username.charAt(0).toUpperCase()}
									</div>
									<span className="font-medium text-sm italic">
										{user.username}
									</span>
								</div>
								<p className="text-sm">
									{userScore} out of {quizLength}
								</p>
							</div>
							<div className="flex flex-col justify-end gap-2">
								<div className="flex items-center space-x-1">
									<div className="w-[30px] h-[30px] bg-gray-300 text-white flex items-center justify-center rounded-full text-xs">
										{otherPlayerUsername!
											.charAt(0)
											.toUpperCase()}
									</div>
									<span className="text-right font-medium text-sm italic">
										{otherPlayerUsername}
									</span>
								</div>
								<p className="text-sm">
									{otherPlayerScore} out of {quizLength}
								</p>
							</div>
						</div>
					</header>
					{!isGameCompleted ? (
						<main>
							<p className="text-sm text-text-secondary mb-1">
								{activeQuiz?.emoji && `${activeQuiz?.emoji} `}
								{gameRef.current.quizTitle}
							</p>
							<p className="mb-1">
								{currentQuestionIndex + 1}/{quizLength}
							</p>
							<h2 className="text-3xl font-bold mb-8">
								{displayedQuestion}
							</h2>
							<ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
								{displayedAnswers &&
									displayedAnswers
										.slice(0, 4)
										.map((answer, index) => (
											<li key={index}>
												<div
													key={index}
													onClick={() =>
														handleAnswerSelect(
															index
														)
													}
													className={`relative text-center py-8 px-4 rounded-2xl cursor-pointer quiz-answer-option ${
														selectedAnswer ===
															index && "selected"
													} ${
														isAnswerShow &&
														index ===
															activeGame!
																.questions[
																currentQuestionIndex
															].correctIndex &&
														"correct"
													} ${
														isAnswerShow &&
														index ===
															selectedAnswer &&
														index !==
															activeGame!
																.questions[
																currentQuestionIndex
															].correctIndex &&
														"incorrect"
													}`}
												>
													{answer}
													{isAnswerShow &&
														index ===
															otherPlayerAnswerShow && (
															<div className="absolute left-0 top-0 transform -translate-x-1/2 -translate-y-1/2 w-[30px] h-[30px] bg-gray-300 text-white flex items-center justify-center rounded-full text-xs">
																{otherPlayerUsername!
																	.charAt(0)
																	.toUpperCase()}
															</div>
														)}
												</div>
											</li>
										))}
							</ul>
						</main>
					) : (
						<Loader />
					)}
				</>
			)}

			{suddenDeath && !suddenDeathSeen.current && <SuddenDeath />}
		</>
	);
};

export default MultiplayerFlow;
