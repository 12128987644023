// components/LoginModal.tsx
import { useEffect, useCallback, useRef, Fragment } from "react";
import {
	Dialog,
	Transition,
	TransitionChild,
	DialogPanel,
	DialogTitle,
} from "@headlessui/react";
import { useUserContext } from "contexts/authContext";

interface LoginModalProps {
	onLoginSuccess: (user: AuthUser) => void;
	onLoginFail: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({
	onLoginSuccess,
	onLoginFail,
}) => {
	const { user } = useUserContext();
	const usernameRef = useRef(user!.gameUser.username);
	const scoreRef = useRef(user!.gameUser.allTimeAnswers);

	const loginCallback = useCallback(
		async (authResult: any) => {
			console.log(authResult);
			onLoginSuccess(user!);
		},
		[onLoginSuccess]
	);

	return (
		<Transition appear show={true} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onLoginFail}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</TransitionChild>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white px-6 py-8 text-left align-middle shadow-xl transition-all flex flex-col gap-6">
								<DialogTitle
									as="h2"
									className="text-2xl text-center max-w-[300px] mx-auto"
								>
									Login for free to create your own quizzes
								</DialogTitle>
								<div className="font-semibold p-4 bg-theme-back-4 border rounded-xl border-theme-back flex flex-col gap-2">
									<ul className="mx-4 list-disc">
										<li>Generate 3 free quizzes per day</li>
										<li>Save your progress</li>
										<li>Play online</li>
									</ul>
								</div>

								<div
									className="mt-4"
									id="firebaseui-auth-container"
								></div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default LoginModal;
