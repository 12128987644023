import logo from "../assets/images/bb_logo.png";
import cube from "../assets/images/cube.png";
import welcomeBg from "../assets/images/welcome_bg.png";
import appleDownloadImg from "../assets/images/download_app_store.png";

const Welcome = () => {
	return (
		<>
			<main>
				<div className="mt-2 mx-auto flex flex-col items-center gap-4">
					<img src={logo} alt="" width="250px" />
					<p className="text-lg text-center max-w-[300px]">
						Thousands of unique trivia games about{" "}
						<span className="italic font-semibold">any</span> topic
					</p>
				</div>
				<img
					src={cube}
					alt=""
					className="max-w-[320px] md:max-w-[450px] mx-auto"
				/>
			</main>
			<footer className="mb-4 bg-transparent">
				<div className="rounded-2xl bg-white mb-4 flex flex-col gap-3 p-4">
					<p className="font-semibold">
						🧠 Play trivia games generated for other users
					</p>
					<p className="font-semibold">
						✏️ Make your own unique games
					</p>
					<p className="font-semibold">
						🎮 Play solo, with friends or online
					</p>
				</div>
				<div className="rounded-2xl bg-white flex flex-col justify-center items-center gap-2 p-4">
					<a href="https://apps.apple.com/us/app/big-brain-energy-ai-trivia/id6504838385">
						<img
							src={appleDownloadImg}
							alt=""
							style={{ maxWidth: 200 }}
						/>
					</a>
				</div>
				<img
					src={welcomeBg}
					alt=""
					className="absolute bottom-0 left-0 w-full h-auto -z-10"
					style={{ maxHeight: "100%" }}
				/>
			</footer>
		</>
	);
};

export default Welcome;
