const StartCountdown: React.FC<{ countdown: number }> = ({ countdown }) => {
	return (
		<div className="mt-6 mx-auto">
			<h2 className="text-center">
				Game starts in{" "}
				<span className="font-semibold text-theme-brand-purple">
					{countdown}
				</span>{" "}
				seconds...
			</h2>
		</div>
	);
};

export default StartCountdown;
